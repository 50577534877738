<template>
  <div class="content__container">
    <p class="promo promo_blocks2">{{ "s4p11_text_1" | localize }}</p>
    <form @submit.prevent="submitHandler">
      <div v-for="(acc, k) in accounts" :key="k">
        <div class="promo__container" v-if="clientCount > 1">
          <p class="promo">{{ "account" | localize }} {{ k + 1 }}</p>
          <button type="button" v-if="accounts.length > 1" class="delete" @click="deleteAccount(k)"></button>
        </div>
        <div :class="'scroll_to_' + k"></div>

        <div class="form__group">
          <v-select
            v-model.lazy="acc.expense"
            @blur="check(k, 'expense')"
            :class="{ invalid: checks[k].expense && !acc.expense }"
            item-text="name"
            item-value="value"
            :items="getList('s4p11_select_1_value_', 9)"
            :label="'s4p11_label_1' | localize"
            :placeholder="'select' | localize"
            :menu-props="{ offsetY: true }"
            append-icon="icon-arrow-down"
            outlined
          ></v-select>

          <v-text-field
            v-if="acc.expense > 0 && acc.expense < 8"
            v-model.lazy="acc.price"
            class="input_number"
            inputmode="numeric"
            pattern="[0-9]*"
            @blur="check(k, 'price')"
            @keypress="onKeyPressHandler"
            @keyup="onKeyUpArrayHandler(acc.price, k, 'price')"
            :class="{ invalid: checks[k].price && !acc.price }"
            :label="'s4p11_label_2' | localize"
            :placeholder="'s4p9_placeholder_1' | localize"
            append-icon="icon-₪"
            outlined
          ></v-text-field>
        </div>

        <div class="form__group">
          <v-textarea
            solo
            auto-grow
            v-if="acc.expense == 8"
            v-model.lazy="acc.text"
            @blur="check(k, 'text')"
            :class="{ invalid: checks[k].text && !acc.text }"
            :placeholder="'s4p11_text_2' | localize"
          ></v-textarea>
        </div>

        <div class="form__group">
          <v-select
            v-if="(acc.expense > 2 && acc.expense < 6) || acc.expense == 7"
            v-model.lazy="acc.month"
            :class="{ invalid: checks[k].month && !acc.month }"
            item-text="name"
            item-value="value"
            @blur="check(k, 'month')"
            :items="getList('month_', 12)"
            :label="'s4p11_label_3' | localize"
            :placeholder="'s4p11_placeholder_1' | localize"
            :menu-props="{ offsetY: true }"
            append-icon="icon-arrow-down"
            outlined
          ></v-select>

          <v-autocomplete
            v-if="(acc.expense > 2 && acc.expense < 6) || acc.expense == 7"
            v-model.lazy="acc.bank"
            :items="banks"
            @blur="check(k, 'bank')"
            :class="{ invalid: checks[k].bank && !acc.bank }"
            item-text="name"
            item-value="value"
            cache-items
            :label="'s4p11_label_4' | localize"
            :placeholder="'s4p11_placeholder_2' | localize"
            append-icon="icon-arrow-down"
            outlined
          ></v-autocomplete>
        </div>

        <div class="form__group">
          <v-select
            v-if="members > 1"
            v-model.lazy="acc.client"
            @blur="check(k, 'client')"
            :class="{ invalid: checks[k].client && !acc.client }"
            :items="clients"
            item-text="name"
            item-value="value"
            :label="'s3p7_label_3' | localize"
            :placeholder="'select' | localize"
            :menu-props="{ offsetY: true }"
            append-icon="icon-arrow-down"
            outlined
          ></v-select>
        </div>
      </div>

      <div class="error__container">
        <p class="error" v-if="error">{{ error | localize }}</p>
      </div>

      <button type="button" class="button button__form" :class="{ button__form_disabled: !success }" @click="submitHandler">
        {{ "form_continue" | localize }}
      </button>

      <button type="button" class="button button__form button__form_empty" @click="addAccount(scheme)">
        + {{ "form_add_account" | localize }}
      </button>
    </form>
  </div>
</template>

<script>
import { clientMixin } from '@/mixins/clientMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'

export default {
  name: 'About_expenses',
  mixins: [clientMixin, commonMixin, numberMixin],
  props: { 
    service: { type: String, required: true },
    nextlink: { type: String, required: true }
  },
  data: () => ({
    scheme: { expense: null, price: null, month: null, bank: null, text: null }
  }),
  created () {
    const data = this.$cookies.get(this.service) || {}

    // Получатели и данные
    this.members = data.saved_clients ? data.saved_clients.length : 1
    this.clients = data.saved_clients || []
    this.accounts = data.expenses || []
    if (this.members > 1) this.scheme.client = null

    this.prepareScheme(this.scheme)
    this.success = !this.checkError()
  },
  computed: { loaded_banks () { return this.$store.state.banks} },
  watch: { loaded_banks () { this.setBanks() } },
  mounted () { this.accounts = this.changePrices(this.accounts, { price: 0 }) },
  methods: {
    setDefaults (value = true) {
      for (const idx of this.accounts.keys()) {
        if (this.accounts[idx].expense === 8) {
          this.accounts[idx].price = value ? 0 : null
          this.accounts[idx].month = value ? 0 : null
          this.accounts[idx].bank = value ? 0 : null
        }
        if (this.accounts[idx].expense === 9) {
          this.accounts[idx].price = value ? 0 : null
          this.accounts[idx].month = value ? 0 : null
          this.accounts[idx].bank = value ? 0 : null
          this.accounts[idx].text = value ? 0 : null
        }
        if (this.accounts[idx].expense > 0 && this.accounts[idx].expense < 8) {
          this.accounts[idx].text = value ? 0 : null
        }
        if (
          this.accounts[idx].expense === 1 ||
          this.accounts[idx].expense === 2 ||
          this.accounts[idx].expense === 6
        ) {
          this.accounts[idx].month = value ? 0 : null
          this.accounts[idx].bank = value ? 0 : null
        }
      }
    },
    submitHandler () {
      this.setDefaults()
      const errorKey = this.checkError(true)
      if (errorKey !== false) {
        this.$scrollTo('.scroll_to_' + errorKey, 300, { offset: -150 })
        this.success = false
        return
      }

      this.success = true
      this.setDefaults(false)
      const data = this.$cookies.get(this.service) || {}
      data.expenses = this.changePrices(this.accounts, { price: 0 }, true)
      this.$cookies.set(this.service, data)
      this.saveClientData('client_expenses', this.nextlink, this.service)
    }
  }
}
</script>